import Link from 'next/link';
import React from 'react';
import Image from 'next/legacy/image';
import { addBaseUrl, removeBaseUrl } from '@/utils/url';
import { WagtailPage, WagtailStreamfieldImage } from '@/types';
import RawHtml from '@components/RawHtml';

type LinkTile = {
  title: string;
  button_page: WagtailPage | null;
  button_url: string | null;
  image: WagtailStreamfieldImage | null;
  svg: string | null;
  tag: string | null;
};

type Props = {
  link_kacheln: LinkTile[];
};

const Links = (props: Props) => {
  const { link_kacheln } = props;

  return (
    <div className="streamfield links">
      {link_kacheln.map(tile => (
        <Link
          key={tile.title}
          href={
            tile.button_page
              ? removeBaseUrl(tile.button_page.full_url)
              : tile.button_url ?? ''
          }
          passHref
          className="links__tile"
        >
          <div className="title">{tile.title}</div>
          {tile.tag ? <div className="links__tile-tag">{tile.tag}</div> : null}
          {tile.image ? (
            <Image
              src={addBaseUrl(tile.image.file.src)}
              alt={tile.image.file.alt}
              layout="intrinsic"
              objectFit="contain"
              className="icon"
              width={78}
              height={78}
            />
          ) : tile.svg ? (
            <RawHtml className="icon" html={tile.svg} />
          ) : null}
        </Link>
      ))}
    </div>
  );
};

export default Links;
