import { WagtailStreamfieldImage } from '@/types';
import Image from 'next/legacy/image';
import { addBaseUrl } from '@/utils/url';
import RawHtml from '@components/RawHtml';

type Tiles = {
  iconsvg: string;
  iconimage: WagtailStreamfieldImage | null;
  infotext?: string;
  tiletext?: string;
};

type Props = {
  title?: string;
  tiles: Tiles[];
};

const FeatureTiles = (props: Props) => {
  const { tiles, title } = props;
  return (
    <div className="streamfield featuretiles">
      <h2>{title}</h2>
      <div className="featuretiles__wrapper">
        {tiles.map((tile, index) => (
          <div className="featuretile" key={index}>
            <div className="featuretile__bgwrapper">
              {tile.iconimage ? (
                <div className="card__iconwraper">
                  <Image
                    src={addBaseUrl(tile.iconimage.file.src)}
                    alt={tile.iconimage.file.alt}
                    layout="intrinsic"
                    objectFit="contain"
                    className="featuretile__icon"
                    width="120"
                    height="120"
                  />
                </div>
              ) : tile.iconsvg ? (
                <RawHtml className="featuretile__icon" html={tile.iconsvg} />
              ) : null}
              <div className="featuretile__text">
                {tile.infotext ? tile.infotext : ''}
              </div>
            </div>
            <div className="featuretile__text--small">
              {tile.tiletext ? tile.tiletext : ''}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeatureTiles;
