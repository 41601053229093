import React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import RawHtml from '@components/RawHtml';
import { ChevronDownIcon } from '@radix-ui/react-icons';

type Props = {
  title: string;
  text: string;
};

const Accordion = (props: Props) => {
  const { title, text } = props;

  return (
    <>
      <AccordionPrimitive.Item className="accordion" value={title}>
        <AccordionPrimitive.Header className="accordion__header">
          <AccordionPrimitive.Trigger className="accordion__trigger">
            {title}
            <ChevronDownIcon className="accordion__icon" />
          </AccordionPrimitive.Trigger>
        </AccordionPrimitive.Header>
        <AccordionPrimitive.Content className="accordion__content">
          <RawHtml html={text} />
        </AccordionPrimitive.Content>
      </AccordionPrimitive.Item>
    </>
  );
};

export default Accordion;
