import SliderComponent from '@components/Slider';

import { SliderImage, WagtailStreamfieldImage } from '@/types';
import { addBaseUrl } from '@utils/url';
import Image from 'next/image';
import Picture from '@components/Picture/Picture';
import Debug from '@components/Debug';

type Props = SliderImage[];

function Slider(props: Props) {
  // return <Debug data={props} />;

  if (!props) {
    return null;
  }

  return (
    <div className="streamfield slider">
      <SliderComponent>
        {Object.values(props).map(image =>
          image.value.mobile ? (
            <Picture
              key={image.id}
              image={addBaseUrl(image.value.file.src)}
              mobileImage={addBaseUrl(image.value.mobile.src)}
              className="slider__image"
              common={{
                alt: image.value.file.alt,
                width: image.value.mobile.width,
                height: image.value.mobile.height,
              }}
            />
          ) : (
            <Image
              key={image.id}
              src={addBaseUrl(image.value.file.src)}
              alt={image.value.file.alt}
              fill
              className="slider__image"
            />
          ),
        )}
      </SliderComponent>
    </div>
  );
}
export default Slider;
