import RawHtml from '@components/RawHtml';
import { transformToHTML5 } from '@utils/html';

type Props = {
  quote: string;
  caption?: string;
};

const Quote = (props: Props) => {
  const { quote, caption } = props;
  return (
    <div className="streamfield quote">
      <figure>
        <blockquote>
          <q>{quote}</q>
        </blockquote>
        {caption ? <figcaption>– {caption}</figcaption> : null}
      </figure>
    </div>
  );
};

export default Quote;
