/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { unstable_getImgProps as getImgProps } from 'next/image';

type Props = {
  image: string;
  mobileImage: string;
  className?: string;
  fill?: boolean;
  common: { alt: string; width: number; height: number };
};

function Picture(props: Props) {
  const { image, mobileImage, className, fill, common } = props;
  // const common = { alt: image.alt, width: image.width, height: image.height };

  const {
    props: { srcSet: mobile },
  } = getImgProps({
    src: mobileImage,
    fill,
    width: !fill ? common.width : undefined,
    height: !fill ? common.height : undefined,
    alt: common.alt,
  });

  const {
    props: { srcSet: desktop, ...rest },
  } = getImgProps({
    src: image,
    fill,
    width: !fill ? common.width : undefined,
    height: !fill ? common.height : undefined,
    alt: common.alt,
  });
  return (
    <picture>
      <source media="(min-width: 600px)" srcSet={desktop} />
      <source srcSet={mobile} />
      <img {...rest} className={className} />
    </picture>
  );
}

export default Picture;
