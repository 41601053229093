/* eslint-disable react/display-name */

import BasePage from './BasePage';
import React, { FunctionComponent } from 'react';

import type { Props as BasePageProps } from './BasePage';

export { BasePageProps };

export const basePageWrap = <Props extends BasePageProps>(
  Component: FunctionComponent<Props>,
) => {
  const displayName = Component.displayName ?? Component.name ?? 'Component';
  Component.displayName = `basePageWrap(${displayName})`;

  return (props: Props) => {
    return (
      <BasePage {...props} _class={Component.name}>
        <Component {...props} />
      </BasePage>
    );
  };
};

export default BasePage;
