import React from 'react';
import { WagtailVideo } from '@/types';

type Props = {
  video: WagtailVideo;
};

const Video = (props: Props) => {
  const { video } = props;

  return (
    <div className="streamfield video">
      <video
        preload="none"
        width="100%"
        controls
        poster={video.thumbnail ?? ''}
      >
        <source
          src={video.file}
          // type={`video/${video.file.split('.')[1] ?? 'mp4'}`}
        />
        {video.transcodes.map(transcode =>
          !transcode.processing ? (
            <source
              key={transcode.id}
              src={transcode.file}
              type={`video/${transcode.media_format}`}
            ></source>
          ) : null,
        )}
      </video>
    </div>
  );
};

export default Video;
