import React from 'react';

import { WagtailPage } from '@/types';
import TabsComponent from '@components/Tabs';
import RawHtml from '@components/RawHtml';
import { removeBaseUrl } from '@utils/url';
import Link from 'next/link';

type StreamfieldTabLink = {
  button_page?: WagtailPage | null;
  button_url?: string | null;
  button_text?: string;
  icon?: string;
};

type StreamfieldTab = {
  text: string;
  title: string;
  icon: string;
  links: StreamfieldTabLink[];
};

type Props = {
  title: string;
  text: string;
  tabs: StreamfieldTab[];
};

const Tabs = (props: Props) => {
  const { title, text, tabs } = props;

  return (
    <div className="streamfield tabs">
      <h2>{title}</h2>
      <RawHtml className="" html={text} />

      <TabsComponent
        aria-label=""
        items={tabs.map((tab, index) => ({
          id: `${index}`,
          trigger: (
            <>
              <div className="tabs__trigger-title">{tab.title}</div>

              <RawHtml className="tabs__trigger-icon" html={tab.icon} />
            </>
          ),
          content: (
            <>
              <div className="tabs__content-left">
                {tab.links.map((link, l_index) => (
                  <Link
                    key={l_index}
                    href={
                      link.button_page
                        ? removeBaseUrl(link.button_page.full_url)
                        : link.button_url ?? ''
                    }
                    passHref
                  >
                    <RawHtml className="icon" html={link.icon} />
                    {link.button_text}
                  </Link>
                ))}
              </div>
              <RawHtml className="tabs__content-right" html={tab.text} />
            </>
          ),
        }))}
      ></TabsComponent>
    </div>
  );
};

export default Tabs;
