'use client';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { PropsWithChildren } from 'react';

import '@splidejs/react-splide/css';

type Props = {};

function Slider(props: PropsWithChildren<Props>) {
  const { children } = props;
  if (!Array.isArray(children))
    return <div className="slider-image">{children}</div>;
  return (
    <Splide
      options={{
        mediaQuery: 'min',
        type: 'loop',
        padding: '5rem',
        gap: '2rem',
        focus: 'center',
      }}
    >
      {children.map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SplideSlide key={index}>{child}</SplideSlide>
      ))}
    </Splide>
  );
}

export default Slider;
