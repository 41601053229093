import { Accordion } from '@/types';
import React from 'react';
import AccordionsComponent from '@components/Accordions';

type Props = {
  accordions: Accordion[];
  blocktitle?: string;
};

const Accordions = (props: Props) => {
  const { accordions, blocktitle } = props;

  return (
    <div className="streamfield accordions">
      {blocktitle ? <h2 className="accordions__header">{blocktitle}</h2> : ''}

      <AccordionsComponent className="" accordions={accordions} />
    </div>
  );
};

export default Accordions;
