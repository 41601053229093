import React from 'react';
import { Company } from './models';
import Richtext from '@streamfields/Richtext';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import Link from 'next/link';
import { WagtailPage } from '@/types';
import { removeBaseUrl } from '@/utils/url';

import BonusweltCompany from '@components/BonusweltCompany';

interface Props {
  title: string;
  description: string;
  overview_page: WagtailPage;
  companies: Array<Record<'company', Company>>;
}

const BonusweltSlider: React.FC<Props> = props => {
  const companies = props.companies.map(c => c.company);

  return (
    <div className="bonuswelt">
      <div className="bonuswelt__before_slide">
        <h2 className="bonuswelt__title">{props.title}</h2>
        <div className="bonuswelt__description">
          <Richtext sourcecode={props.description}></Richtext>
        </div>
      </div>
      <div className="bonuswelt__splide">
        <Splide
          options={{
            mediaQuery: 'min',
            rewind: true,
            gap: '20px',
            arrowPath: 'm 13.000027,8 14,11.994 -14,12.006',
            perPage: 1,
            arrows: false,
            padding: { left: 0, right: 60 },
            breakpoints: {
              640: {
                perPage: 2,
                padding: '0',
              },
              820: {
                arrows: true,
              },
            },
          }}
        >
          {companies.map((company, index) => (
            <SplideSlide key={index}>
              <BonusweltCompany company={company}></BonusweltCompany>
            </SplideSlide>
          ))}
        </Splide>
      </div>

      <div className="bonuswelt__after_slide">
        {props.overview_page ? (
          <div className="bonuswelt__button_uebersicht">
            <Link href={removeBaseUrl(props.overview_page.full_url)}>
              Zur Angebotsübersicht
            </Link>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default BonusweltSlider;
