import Link from 'next/link';
import Image from 'next/legacy/image';
import React from 'react';
import clsx from 'clsx';
import { addBaseUrl, removeBaseUrl } from '@/utils/url';
import { WagtailPage, WagtailStreamfieldImage } from '@/types';
// import Link from 'next/link';
import RawHtml from '@components/RawHtml';

type Card = {
  button_page: WagtailPage | null;
  button_url: string | null;
  buttonLink?: boolean;
  text: string;
  title: string;
  svg: string;
  image: WagtailStreamfieldImage | null;
};

type Props = {
  roundImage?: boolean;
  title?: string;
  text?: string;
  bottomtext?: string;
  background: string;
  cards: Card[];
};

const Cards = (props: Props) => {
  const { cards, title, text, background, bottomtext, roundImage } = props;

  return (
    <div className={clsx('cards', background)}>
      <div className={clsx(roundImage ? 'roundImg' : '')}>
        <div className="cards__header container">
          <h2 className="cards__title">{title}</h2>
          <RawHtml html={text} />
        </div>
        <div className="cards__wrapper">
          {cards.map((card, index) => (
            <div className="card" key={index}>
              {card.image ? (
                <div className="card__iconwraper">
                  <Image
                    src={addBaseUrl(card.image.file.src)}
                    alt={card.image.file.alt}
                    layout="intrinsic"
                    objectFit={roundImage ? 'cover' : 'contain'}
                    className="card__icon"
                    width={roundImage ? '240' : '78'}
                    height={roundImage ? '240' : '78'}
                  />
                </div>
              ) : card.svg ? (
                <RawHtml className="card__icon" html={card.svg} />
              ) : null}
              <h2 className="card__title">{card.title}</h2>
              <RawHtml className="card__text" html={card.text} />
              {card.button_page || card.button_url ? (
                <Link
                  href={
                    card.button_page
                      ? removeBaseUrl(card.button_page.full_url)
                      : card.button_url ?? ''
                  }
                  passHref
                  className={clsx(
                    card.buttonLink
                      ? 'button button--primary card__link'
                      : 'card__link',
                  )}
                >
                  Zur Seite
                </Link>
              ) : null}
            </div>
          ))}
        </div>
        <RawHtml className="cards__footer container" html={bottomtext} />
      </div>
    </div>
  );
};

export default Cards;
