import RawHtml from '@components/RawHtml';
import clsx from 'clsx';
import React from 'react';

type Props = {
  type: 'warning' | 'success' | 'error' | 'information';
  title: string;
  text: string;
};

const Message = (props: Props) => {
  const { type, title, text } = props;

  return (
    <div
      className={clsx('streamfield', 'message', 'flow', {
        'message--success': type === 'success',
        'message--error': type === 'error',
        'message--warning': type === 'warning',
        'message--information': type === 'information',
      })}
    >
      <h2 className="message__title">{title}</h2>
      <RawHtml html={text} />
    </div>
  );
};

export default Message;
