import RawHtml from '@components/RawHtml';
import React from 'react';

type Props = {
  value?: string;
  sourcecode?: string;
};

const Richtext = (props: Props) => {
  const { value, sourcecode } = props;
  return (
    <RawHtml className="streamfield richtext" html={value ?? sourcecode} />
  );
};

export default Richtext;
