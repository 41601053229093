import React from 'react';
import { Streamfield } from '../types';

import streamfields from '@/streamfields';

type Props = {
  fields: Streamfield[];
};

const Streamfields = (props: Props) => {
  const { fields } = props;

  return (
    <>
      {fields.map(field => {
        const Component = streamfields[field.type];
        if (!Component) {
          return (
            <h2 key={field.id}>
              Component <strong>{field.type}</strong> not found
            </h2>
          );
        }

        return (
          <Component
            key={field.id}
            {...(typeof field.value === 'string'
              ? { value: field.value }
              : field.value)}
          />
        );
      })}
    </>
  );
};

export default Streamfields;
